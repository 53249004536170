.file-grid {
    box-sizing: border-box;
    display: block;
    min-width: 100%;
    min-height: 100%;
    padding: 8px 0 0 8px;
}

.file-grid  .file-item {
    display: inline-grid;
    cursor: pointer;
    position: relative;
    width: 198px;
    height: 148px;
    background-color: #84DCC6;
    border: 1px solid lightgray;
    border-radius: 2px;
    margin: 0 8px 8px 0;
    vertical-align: top;
}

@media (max-width: 767px){
    .file-grid  .file-item {
        width: calc(50vw - 16px);
    }
}

.file-grid .file-item:hover {
    background: dimgray !important;
}

.file-grid .icon {
    height: 46px;
    text-align: center;
    position: relative;
    top: calc(50% - 46px);
    font-size: 42px;
    color: white;
}

.file-grid .icon svg {
    height: 46px;
    width: 46px;
    vertical-align: initial !important;
}

.file-grid .file-detail {
    bottom: 0;
    background: white;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 8px;
}

.file-grid .file-props {
    display: flex;
    justify-content: space-between;
}

.file-grid .filename {
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #6A00B6;
    font-family: "Tinos";
    font-weight: 700;
}

.file-grid .filesize {
    display: inline;
    text-align: left;
    font-size: 12px;
    color: #6A00B6;
    font-family: "Tinos";
    font-weight: 700;
}

.file-grid .filetime {
    display: inline;
    text-align: right;
    font-size: 12px;
    color: #6A00B6;
    font-family: "Tinos";
    font-weight: 700;
}
